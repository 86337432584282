
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Ionicons
@import "node_modules/ionicons/dist/scss/ionicons.scss";

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

body{
	-webkit-font-smoothing: antialiased;
	font-weight: 400 !important;
}

//Code Here for the Admin Lte
.content-header > .breadcrumb{
	margin-left: 15px;
	margin-right: 15px;
}

.header-title{
	display: inline-block;
	padding-right: 15px;
}

.title-page{
    padding: 0 15px;
}

.action-db-button{
	white-space: nowrap;
}

.admin-data-table tr th{
	white-space: nowrap;
}

.delete-form{
	display: inline;
}

.table.table-bordered.dataTable {
    border-collapse: collapse !important;
}

.select2-container .select2-selection--single{
    height: 36px !important;
    border-radius: 0 !important;
    width: 100%;
}

.drop-upload{
	height: 300px;
}